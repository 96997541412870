.disabled {
    pointer-events: none; 
    opacity: 0.5; 
    
  }
  .highcharts-figure {
    margin: 0 auto !important;
    max-width: 900px !important;
    min-width: 360px !important;
  }
  .scroll-buttons {
    visibility: hidden;
  }
  @media (max-width: 767px) {
    .highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span {
      white-space: normal !important;  
      word-wrap: break-word !important;  
      text-overflow: unset !important;  
      overflow: visible !important;  
      font-size: 0.50rem !important;
      text-align: center !important;
    }
    .scroll-buttons {
      display: flex;
      justify-content: end;
      visibility: visible;
      padding: 8px;
    }
    .icon-button-indicators {
      background-color: white;
      border-radius: 50%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s ease;
      margin-left: 10px;
      color: #113689;
      border: 1px solid #ddd;
  }
  
  .icon-button-indicators:hover {
      background-color: #f0f0f0;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
      transform: scale(1.1);
      color: #113689;
      border: 1px solid #ddd;
  }
  }
 

  
  
  .ag-header-group-cell .ag-header-cell-comp-wrapper{justify-content: center;}
