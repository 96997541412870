.graph-wrap{
    background: none;
    height: 72vh;
}

.graph-wrap>div{
    height: 71vh;
}

.mapindia{margin-top: 20px;}

.highcharts-background{
    background: none;
    width: 0px!important;
}


  

@media (max-width: 1599px) and (min-width: 1400px) {
    .graph-wrap{
        height: 480px;
    }
    
    .graph-wrap>div{
        height: 470px;
    }
}

@media (max-width: 1399px) and (min-width: 1024px) {
    .graph-wrap{
        height: 390px;
    }
    
    .graph-wrap>div{
        height: 370px; 
    }
}
@media (max-width: 1023px) {
    .graph-wrap{
        height: 450px;
        // width: 100%;
        position: relative;
    }
    
    .graph-wrap>div{
        height: 440px;
        width: 95%;
    }
}